<template>
  <div class="study-container">
    <div class="study-sidebar">
      <b-nav vertical pills>
        <LoadingSpinner :is-loaded="isLoaded">
          <b-nav-item
            v-for="material of materials"
            :key="material.slug"
            :to="{ name: 'study-material', params: { slug: material.slug } }"
          >
            {{ material.title }}
          </b-nav-item>
        </LoadingSpinner>
      </b-nav>
    </div>
    <div v-if="materials.length > 0">
      <router-view />
    </div>
    <div v-else>
      <b-row>
        <b-col>
          <p class="no-materials">Žiadne studijné materiály</p>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { apiStudyMaterials, LoadingSpinner } from "frontend-common";

export default {
  name: "StudyMaterialsLayout",
  mixins: [apiStudyMaterials],
  components: {
    LoadingSpinner,
  },
  props: {},
  data() {
    return {
      materials: [],
      isLoaded: false,
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.apiStudyMaterials()
      .then((response) => {
        this.materials = response;
      })
      .finally(() => (this.isLoaded = true));
  },
  methods: {},
};
</script>

<style scoped>
.navbar {
  padding-inline: 2em;
  border-right: 1px solid #000;
}

.study-container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

@media (min-width: 992px) {
  .study-container {
    flex-direction: row;
  }
}

.study-sidebar {
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding-inline: 0.5em;
  padding-block: 1em;
  min-height: 100%;
}

.no-materials {
  text-align: center;
}
</style>
